.MapPage {
  --ion-background-color: var(--ion-color-tertiary);

  &__content {
    position: relative;
  }

  &__search-toolbar {
    // Ensure search toolbar is above suggestions backdrop
    z-index: 2;
  }

  &__suggestions-menu {
    z-index: 1;
    pointer-events: all;
  }

  &__buttons-toolbar {
    z-index: 0;
  }

  &__map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__overlay {
    // Hide the ion-header drop shadow
    &::after {
      content: none;
    }

    // Draw the map behind the ion-toolbars
    ion-toolbar {
      --background: transparent;
      --border-style: none;
    }

    ion-searchbar {
      --background: #ffffff;
      // Fix default padding, which is `1px unset 15px unset` and looks bad.
      padding: 12px;
    }

    // Ignore clicks so they propagate to the map behind the overlay...
    pointer-events: none;

    // ...except for clicks on header UI elements
    ion-searchbar,
    ion-button {
      pointer-events: all;
    }
  }
}
