.DealListItem {
  $tint-color: var(--tint-color, #555555);

  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  background: #ffffff;

  /* Listing Drop Shadow */
  box-shadow: 0 3px 6px rgba(0 0 0 / 6%);
  border-radius: 15px;

  &__circle {
    width: 40px;
    height: 40px;
    flex: none;
    border-radius: 50%;
    background-color: $tint-color;
    border: 1px solid $tint-color;
    overflow: hidden;
  }

  &__biz-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__stack {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__biz-name {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: -0.1px;
    color: #010101;
    text-decoration: none;
  }

  &__message {
    margin: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    color: $tint-color;
    text-decoration: none;
  }

  &__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    background: $tint-color;
    border-radius: 100px;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 1.8;
    text-align: center;
    letter-spacing: 0.7px;
    color: #ffffff;
    text-transform: uppercase;
  }
}
