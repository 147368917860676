.CircleButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f3faf8;
  color: #03363d;

  &__icon {
    width: 12px;
    height: 12px;
  }
}
