.gm-style {
  // Respect Ionic theme colors in the Google Maps InfoWindow
  .gm-style-iw {
    background: var(--ion-background-color);
    color: var(--ion-text-color);

    // Fix InfoWindow callout arrow in dark theme
    &-t::after {
      background: var(--ion-background-color);
    }

    // Fix InfoWindow close button in dark theme
    button:last-child {
      ion-app.dark & {
        filter: invert(100%);
      }
    }
  }
  // Fix POI InfoWindow in dark theme
  &.poi-info-window {
    div,
    a {
      background: none;
      color: inherit;
    }
  }
}
