ion-modal.modal::part(content) {
  &:global(.md) {
    ion-toolbar ion-button {
      text-transform: capitalize;
      letter-spacing: 0;
    }

    ion-checkbox {
      --background-checked: transparent;
      --border-color: transparent;
      --border-color-checked: transparent;
      --checkmark-color: var(--ion-color-primary);
    }

    ion-list {
      background: inherit;
    }
  }

  &:global(.ios) {
    ion-list-header {
      margin-top: 10px;
    }

    ion-label {
      color: var(--ion-color-primary);
    }
  }
}
