.ExploreDealCard {
  $card-radius: 20px;
  $tint-color: var(--tint-color, #555555);

  position: relative;
  border-radius: $card-radius;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0 0 0 / 10%);
  margin-bottom: 8px;
  display: grid;
  grid-template-rows: max-content max-content max-content auto max-content;

  &__header-image {
    width: 100%;
    height: 86px;
    background-color: gray;
    border-radius: $card-radius $card-radius 0 0;
    object-fit: cover;
  }

  &__circle {
    margin: -17px 0 0 6px;
    border-radius: 50%;
    width: 34px;
    height: 34px;
    background-color: $tint-color;
    border: 6px solid #ffffff;
    box-sizing: content-box;
    overflow: hidden;
  }

  &__biz-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__biz-name {
    margin: 0 12px;
    color: #010101;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: -0.1px;
    text-decoration: none;
  }

  &__message {
    margin: 8px 12px 12px;
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.3;
    color: $tint-color;
    text-decoration: none;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px 15px;
    background-color: $tint-color;
    border-radius: 100px;
    height: 28px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.3;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;

    &:hover {
      opacity: 0.8;
    }
  }
}
