.WalletPage {
  --ion-background-color: #f3faf8;

  & > ion-content {
    --padding-bottom: 32px;
  }

  &__heading {
    margin: 24px 16px 16px;
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 1.4;
    letter-spacing: -0.01em;
    color: var(--ion-color-primary);
    user-select: none;
  }

  &__filter-list {
    display: flex;
    margin: 16px 16px 32px;
    gap: 0 10px;
  }

  &__filter-option {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 8px 20px;
    font-family: var(--font-family-common);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    background: transparent;
    color: var(--ion-color-primary);
    border-radius: 50px;

    &:hover {
      background: rgba(0 0 0 / 6%);
    }

    &--selected {
      background: var(--ion-color-secondary);

      &:hover {
        /* stylelint-disable-next-line color-function-notation */
        background: rgba(var(--ion-color-secondary-rgb), 50%);
      }
    }
  }

  &__deals-list {
    margin: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
