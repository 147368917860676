@use "sass:math";

.ExploreCategoryCard {
  $circle-size: 34px;
  $icon-size: 16px;
  $icon-margin: math.div($circle-size - $icon-size, 2);

  border-radius: 20px;
  text-decoration: none;
  background: #d1eee3;
  padding: 10px;
  min-height: 88px;
  // Grid layout
  display: grid;
  grid-template-rows: $circle-size auto;
  justify-items: center;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background: rgba(#d1eee3, 0.6);
  }

  &__circle {
    border-radius: 50%;
    width: $circle-size;
    height: $circle-size;
    background: var(--ion-color-primary);
  }

  &__icon {
    margin: $icon-margin;
    width: $icon-size;
    height: $icon-size;
    color: #ffffff;
  }

  &__label {
    margin: 3px 0 0;
    align-self: center;
    text-align: center;
    color: #010101;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 1.1;
  }
}
