@use "./assets.scss" as assets;

@mixin font-face($family, $variant, $weight, $style: normal) {
  // stylelint does not understand that `assets.font` is in scope?
  // https://github.com/stylelint-scss/stylelint-scss/issues/594
  /* stylelint-disable-next-line scss/function-no-unknown */
  $url: assets.font("#{$family}-#{$variant}.woff2");

  @font-face {
    font-family: $family;
    font-weight: $weight;
    font-style: $style;
    src: $url format("woff2");
  }
}

@include font-face("Greed", "SemiBold", 600);
@include font-face("Greed", "SemiBoldItalic", 600, italic);

@include font-face("Inter", "Thin", 100);
@include font-face("Inter", "ExtraLight", 200);
@include font-face("Inter", "Light", 300);
@include font-face("Inter", "Regular", 400);
@include font-face("Inter", "Medium", 500);
@include font-face("Inter", "SemiBold", 600);
@include font-face("Inter", "Bold", 700);
@include font-face("Inter", "ExtraBold", 800);
@include font-face("Inter", "Black", 900);
