.MapButtonsToolbar {
  // Ensure buttons toolbar is below suggestions menu
  z-index: 0;

  ion-button {
    // Override clear button styles with background and border.
    // We can't use `fill="solid"` because it changes the shape of the button,
    // and `shape="round"` does not seem to work
    &:global(.button-clear) {
      --background: var(--ion-item-background, var(--ion-background-color));

      &:global(.md) {
        --color: var(--ion-text-color);
        --border-width: 1px;
        --border-color: rgba(var(--ion-text-color-rgb) / 30%);
        --border-style: solid;
      }

      &:global(.ios) {
        --color: var(--ion-color-primary);
        --border-style: none;

        box-shadow: 0 0 5px rgba(0 0 0 / 30%);

        ion-app:global(.dark) & {
          --color: var(--ion-text-color);

          box-shadow: none;
        }
      }
    }
  }
}

.MapFilterList {
  ion-list-header:global(.ios) {
    font-size: 14px;
    align-items: center;
    margin-top: 8px;
  }
}
