.MapSuggestionsMenu {
  // Override pointer-events from MapPage__overlay
  pointer-events: all;

  &__anchor {
    position: relative;
    overflow: visible;
    // Ensure anchor is above backdrop
    z-index: 2;
  }

  &__backdrop {
    // Not sure why this isn't getting set automatically
    opacity: var(--ion-backdrop-opacity, 0.32);
    // Ensure backdrop covers map & buttons toolbar
    z-index: 1;
  }

  ion-list {
    --item-height: 48px;
    --max-items: 6;
    // Position list on top of buttons toolbar
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // Attempt to match the padding of the search toolbar
    padding: 0 8px;
    // Show the map behind the menu
    background: transparent;
  }
}

.MapSuggestionsMenuItem {
  ion-icon {
    opacity: 0.7;
  }

  // Override default styles (display)
  ion-label:global(.sc-ion-label-md-h) {
    display: flex;
    align-items: baseline;

    // Add trailing spacing between flex items
    & > *:not(:last-child) {
      margin-right: 8px;
    }
  }
}
