.GoogleMap {
  height: 100%;
  width: 100%;
  background-color: #808080;
  opacity: 0;
  transition: opacity 250ms ease-in;

  &--loaded {
    opacity: 1;
  }
}
