.MainTabs {
  &__tab-bar {
    height: 66px;

    --background: var(--ion-color-primary);
    --border: 0 solid transparent;
    --color: var(--ion-color-secondary);
    --color-selected: #ffffff;
    // --ion-safe-area-bottom: 34px;
  }
}
