.ExploreFavoritesShelf {
  $column-width: minmax(160px, 44%);

  display: grid;
  gap: 0 20px;
  padding: 0 16px 8px;
  grid-template-columns: repeat(auto-fill, $column-width);
  grid-auto-flow: column;
  grid-auto-columns: $column-width;
  overflow-x: auto;
}
