.WelcomePage {
  & > ion-content {
    --padding-bottom: 48px;
  }

  &__logotype {
    display: flex;
    justify-content: center;
    margin: 24px 0 64px;
    color: var(--ion-color-primary);
  }

  &__greeting {
    color: var(--ion-color-primary);
    font-family: var(--font-family-heading);
    font-size: 42px;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;
    margin: 56px 24px;
  }

  &__auth-social {
    margin: 58px 0;
  }

  &__auth-social,
  &__auth-email {
    display: flex;
    flex-direction: column;

    & > * {
      margin: 0 16px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  &__button--lime {
    background-color: var(--ion-color-secondary);
    border-color: var(--ion-color-secondary);
  }

  &__button--sage {
    background-color: var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
  }

  &__button--link {
    background-color: transparent;
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    text-decoration: underline;
  }
}
