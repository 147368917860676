.ExplorePage {
  --ion-background-color: #fdfcf9;

  & > ion-content {
    --padding-bottom: 32px;
  }

  &__heading {
    margin: 24px 16px 16px;
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 1.4;
    letter-spacing: -0.01em;
    color: var(--ion-color-primary);
    user-select: none;
  }

  &__subheading {
    margin: 16px;
    padding: 0;
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.4;
    color: var(--ion-color-primary);
    user-select: none;
  }

  &__grid--categories {
    margin-bottom: 24px;
  }
}
