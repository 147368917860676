.SettingsPage {
  --ion-background-color: #f3faf8;

  & > ion-content {
    --padding-bottom: 48px;
  }

  &__heading {
    margin: 24px 16px 16px;
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 1.4;
    letter-spacing: -0.01em;
    color: var(--ion-color-primary);
    user-select: none;
  }

  &__subheading {
    margin: 24px 16px 20px;
    padding: 0;
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 1.3;
    color: #1d3f35;
    user-select: none;
  }

  &__section {
    display: flex;
    flex-direction: column;
    margin: 12px 16px 24px;
    padding: 12px 0;
    background: #ffffff;
    box-shadow: 0 6px 7px rgba(0 0 0 / 6%);
    border-radius: 30px;
    overflow: hidden;
  }

  &__button {
    display: flex;
    padding: 12px 20px;
    background: transparent;
    color: var(--ion-color-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;

    &:hover {
      background: rgba(0 0 0 / 3%);
    }
  }

  &__button &__icon {
    margin-left: auto;
  }

  &__icon--shortcut {
    width: 16px;
    height: 16px;
  }

  &__contact-blurb,
  &__contact-link {
    margin: 12px 32px;
    color: var(--ion-color-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
  }

  &__contact-link {
    display: block;
  }
}
