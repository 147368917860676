.Button {
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: 20px 48px;
  border-radius: 330px;
  border: 1.5px solid var(--ion-color-primary);
  background-color: #ffffff;
  color: var(--ion-color-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
  text-decoration: none;

  &--has-leading-icon {
    padding-left: 24px;
  }

  &--has-trailing-icon {
    padding-right: 24px;
  }

  &__leading-icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &__trailing-icon {
    width: 24px;
    height: 24px;
    margin-left: 12px;
  }

  &__label {
    flex: 1;
    text-align: center;
  }
}
