.AuthPage {
  & > ion-content {
    --padding-bottom: 32px;
  }

  &__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 16px 32px;
    padding: 0 40px;
    color: var(--ion-color-primary);
  }

  &__logo {
    width: 129px;
    height: 22px;
    // Match the height of the circle button
    margin: 4px 0;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__heading {
    flex: 1;
    user-select: none;
    font-family: var(--font-family-heading);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: -0.25px;
    margin: 32px 16px;
    color: var(--ion-color-primary);
  }

  &__form {
    & > fieldset {
      padding: 0;
      margin: 0 16px;
      border: 0;
      display: flex;
      flex-direction: column;

      & > * {
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }

  &__field {
    display: flex;
    flex-direction: column;
    color: var(--ion-color-primary);

    & > label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 2;
    }

    & > input {
      background-color: transparent;
      outline: 0;
      border: 0 solid rgba(6 53 56 / 50%);
      border-bottom-width: 1px;
      border-radius: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.8;

      &:focus {
        border-color: var(--ion-color-secondary);
      }
    }
  }

  &__error-msg {
    color: var(--ion-color-danger);
  }

  &__spacer {
    height: 24px;
  }

  &__button--sage {
    background-color: #f3faf8;
    border-color: #f3faf8;
  }

  &__button--link {
    background-color: transparent;
    border: none;
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    text-decoration: underline;
  }
}
